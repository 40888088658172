/* App.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Styles supplémentaires pour votre application */
.app-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: linear-gradient(to right, #1A202C, #2D3748);
    color: white;
    font-family: 'Arial', sans-serif;
}